// portal react select menu styles

.ui-select__menu-portal {
  .ui-select__menu {
    @apply p-1 rounded-md border-white/[0.2] border;
    @apply bg-[#141429]/[0.9] backdrop-blur-lg;
  }

  .ui-select__menu-list {
    @apply p-0 m-0;
    &::-webkit-scrollbar{
      display: none;
    }
  }

  .ui-select__option {
    @apply cursor-pointer flex items-center px-2 py-[9px] mt-1 first:mt-0 text-xs leading-3;

    font-size: 12px !important;
    border-radius: 2px;

    &:active {
      background-image: linear-gradient(0deg, rgba(0, 0, 24, 0.1), rgba(0, 0, 24, 0.1)), linear-gradient(180deg, #F47D02 0%, #FB6101 100%);
    }
  }

  .ui-select__option--is-selected  {
    background-color: transparent;
    background-image: none;
  }

  .ui-select__option--is-focused {
    background-image: linear-gradient(180deg, #F47D02 0%, #FB6101 100%);
  }
}

.ui-select__menu-notice {
  @apply text-xs leading-3;
}