@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  line-height: 1;
}

html, body, #__next {
  @apply min-h-full;
}

#__next {
  @apply overflow-x-hidden;
  // disable flex, to allow Next/link scroll top, thread https://github.com/vercel/next.js/issues/42492#issuecomment-1344389841
  // @apply overflow-x-hidden flex flex-col;
}

input {
  color-scheme: dark;
}

body {
  background: linear-gradient(180deg, #000018 24.15%, #35261D 100%), #000018;
  background-attachment: fixed;
  @apply bg-no-repeat text-white;
}

body.landing {
  background: #000018;
}

a {
  &:hover {
    // @apply text-[#4FA8E3]; // cause conflicts in some components
  }
}

.landing-title {
  @apply text-4/5xl md:text-8xl font-bold;
}

.inner {
  @apply w-full max-w-[1120px] mx-auto px-4;
}

@import 'varaibles';
@import 'fonts';
@import 'select';
@import 'theme';
@import 'recharts';

// popover
div[data-radix-popper-content-wrapper] {
  @apply z-[100];
}

// Input number arrows
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield!important; /* Firefox */
}
//\ Input number arrows

.button-base { @apply appearance-none border-none; }

.input-label {
  color: red;

  @apply mb-5 block;
}

.input-group {
  transition: border 0.15s linear, box-shadow 0.15s linear;

  @apply rounded-xl flex items-center w-full justify-between;

  &:focus-within,
  &:hover {
    border-color: red;
    box-shadow: 0 0 0 1px red;

    .input-group-text {
      border-color: red;
    }
  }
}

.input-group-text {
  transition: border 0.15s linear;

  @apply px-4 py-5 whitespace-nowrap;

  &.prefix { border-right: 1px solid #363d45; }
  &.suffix { border-left: 1px solid #363d45; }
}

.input-control {
  color: #fff;

  @apply bg-transparent border-none appearance-none px-4 py-5 flex-grow w-full;
}

.select-control {
  > div {
    @apply box-content w-full;
  }

  > div span + div {
    @apply px-3 py-4 box-content;
  }
}

.select-dropdown-indicator {
  background: url('/assets/images/caveat-icon-gray.svg') no-repeat 50% 50%;
  transition: transform 0.15s linear;
  will-change: transform;
  background-size: contain;
  width: 14px;
  height: 8px;

  @apply inline-block;

  @at-root {
    .input-group .select-wrap:focus-within {
      .select-dropdown-indicator {
        transform: rotate(180deg);
      }
    }
  }
}

// new forms
.form-control {
  @apply text-sm rounded-md border border-solid bg-black/[0.2] appearance-none;
  @apply text-gray-094 leading-4.5;
  @apply w-full px-3 py-2.5;

  &:not(:disabled):not(:read-only) {
    @apply border-[#606060];

    &:hover,
    &:focus {
      @apply border-gray-065;

      ~ .form-addon {
        @apply border-gray-065;
      }
    }

    ~ .form-addon {
      @apply border-[#606060];
    }
  }

  &:read-only {
    @apply border-white/[0.2];
  }

  &:disabled {
    @apply border-white/[0.1] text-brown-037 cursor-not-allowed;

    ~ .form-addon {
      @apply border-white/[0.1];
    }
  }

  &.form-control-with-adddon {
    @apply rounded-r-none border-r-0;
  }

  &.error {
    @apply border-custom-FF504C;

    ~ .form-addon {
      @apply border-custom-FF504C;
    }
  }
}

.form-addon {
  @apply border border-solid rounded-r-lg whitespace-nowrap;

  .btn-root {
    @apply rounded-l-none;
  }
}
