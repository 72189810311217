.theme-RVN {
   @import "HardwareCounter/rvn-hardware-counter";

  .theme-btn {
    &::before { background-image: linear-gradient(246.31deg, #F0513A 7.53%, #384182 106.04%) !important; }
    &::after { background-image: linear-gradient(246.31deg, #384182 7.53%, #F0513A 106.04%) !important; }
  }

  .theme-explore-btn {
    &::before { background-image: linear-gradient(220.14deg, #F0513A -43.14%, rgba(196, 196, 196, 0) 148.78%) !important; }
    &::after { background-image:linear-gradient(220.14deg, #F0513A -43.14%, rgba(196, 196, 196, 0) 148.78%) !important; }
  }

  .theme-text-highlight {
    background-image: linear-gradient(246.31deg, #F0513A 7.53%, #384182 106.04%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .theme-color {
    @apply text-[#E76264];
  }

  .theme-border {
    @apply border-[#E76264];
  }

  .theme-support {
    background: url('/assets/images/support-rvn.png') 50% 50% / contain no-repeat;
  }

  .theme-tutorial-btn.tutorial::after { background: url('/assets/images/tutorial-icon-rvn-hover.svg') 50% 0 no-repeat; }
  .theme-tutorial-btn.video::after { background: url('/assets/images/tutorial-video-icon-rvn-hover.svg') 50% 0 no-repeat; }

  .theme-advanced-calc {
    &:hover {
      &::after {
        opacity: 1;
      }
    }

    &::before {
      background-image: linear-gradient(246.31deg, #F0513A 7.53%, #384182 106.04%);
    }
    &::after {
      background-image: linear-gradient(246.31deg, #384182 7.53%, #F0513A 106.04%);
      opacity: 0;
    }
  }

  .theme-stroke {
    @apply stroke-[#E76264];
  }

  .theme-ui-select .ui-select__control {
    &::before {
      background-image: linear-gradient(221.19deg, #F0513A -43.08%, rgba(196, 196, 196, 0) 123.25%, #2F3D33 123.25%),
      linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04));
    }
    &::after {
      background-image: linear-gradient(221.19deg, #F0513A -43.08%, rgba(196, 196, 196, 0) 123.25%, #2F3D33 123.25%),
      linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04));
    }
  }
}