.theme-BTC {
  @import "HardwareCounter/btc-hardware-counter";

  .theme-advanced-calc {
    &:hover {
      &::after {
        opacity: 1;
      }
    }

    &::before {
      background-image: linear-gradient(262.77deg, #ffeb06 0.86%, #fe5400 100.31%);
    }
    &::after {
      background-image: linear-gradient(262.77deg, #fe5400 0.86%, #ffeb06 100.31%);
      opacity: 0;
    }
  }

  .theme-ui-select .ui-select__control {
    &::before {
      background-image: linear-gradient(0deg, #FF9500, #FF9500), linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08));
    }
    &::after {
      background-image: linear-gradient(0deg, #FF9500, #FF9500), linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08));
    }
  }
}
