.theme-ETC {
  @import "HardwareCounter/etc-hardware-counter";

  .theme-btn {
    &::before { background-image: linear-gradient(244.67deg, #3ab83a 10.58%, rgba(62, 116, 255, 0.52) 86.76%) !important; }
    &::after { background-image: linear-gradient(225deg, #244488 14.64%, #3ab83a 85.36%) !important; }
  }

  .theme-explore-btn {
    &::before { background-image: linear-gradient(220.14deg, rgba(125, 254, 161, 0.85) -43.14%, rgba(196, 196, 196, 0) 148.78%) !important; }
    &::after {  background-image: linear-gradient(220.14deg, rgba(125, 254, 161, 0.85) -43.14%, rgba(196, 196, 196, 0) 148.78%) !important; }
  }

  .theme-text-highlight {
    background-image: linear-gradient(244.67deg, #3ab83a 10.58%, rgba(62, 116, 255, 0.52) 86.76%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .theme-color {
    @apply text-[#3AA952]
  }

  .theme-support {
    background: url('/assets/images/support-etc.png') 50% 50% / contain no-repeat;
  }

  .theme-tutorial-btn.tutorial::after { background: url('/assets/images/tutorial-icon-etc-hover.svg') 50% 0 no-repeat; }
  .theme-tutorial-btn.video::after { background: url('/assets/images/tutorial-video-icon-etc-hover.svg') 50% 0 no-repeat; }

  .theme-advanced-calc {
    &:hover {
      &::after {
        opacity: 1;
      }
    }

    &::before {
      background-image: linear-gradient(262.77deg, #3AA952 0.86%, #3AA952 100.31%);
    }
    &::after {
      background-image: linear-gradient(262.77deg, #3AA952 0.86%, #3AA952 100.31%);
      opacity: 0;
    }
  }

  .theme-stroke {
    @apply stroke-[#F0513A];
  }

  .theme-ui-select .ui-select__control {
    &::before {
      background-image: linear-gradient(221.19deg, rgba(125, 254, 161, 0.85) -43.08%, rgba(196, 196, 196, 0) 123.25%, #2F3D33 123.25%),
      linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04));

    }
    &::after {
      background-image: linear-gradient(221.19deg, rgba(125, 254, 161, 0.85) -43.08%, rgba(196, 196, 196, 0) 123.25%, #2F3D33 123.25%),
      linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04));
    }
  }
}
