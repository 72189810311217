.theme-KAS {
  @import "HardwareCounter/kas-hardware-counter";

  .theme-btn {
    &::before { background-image: linear-gradient(242.56deg, #49EACB 18.11%, #0D86CA 80.66%) !important; }
    &::after { background-image: linear-gradient(242.56deg, #49EACB 18.11%, #0D86CA 80.66%) !important; }
  }

  .theme-explore-btn {
    &::before { background-image: linear-gradient(242.56deg, #49EACB 18.11%, #0D86CA 80.66%) !important; }
    &::after {  background-image: linear-gradient(242.56deg, #49EACB 18.11%, #0D86CA 80.66%) !important; }
  }

  .theme-text-highlight {
    background: linear-gradient(242.56deg, #49EACB 18.11%, #0D86CA 80.66%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .theme-color {
    @apply text-[#49EACB]
  }

  .theme-support {
    background: url('/assets/images/support-etc.png') 50% 50% / contain no-repeat;
  }

  .theme-tutorial-btn.tutorial::after { background: url('/assets/images/tutorial-icon-etc-hover.svg') 50% 0 no-repeat; }
  .theme-tutorial-btn.video::after { background: url('/assets/images/tutorial-video-icon-kas-hover.svg') 50% 0 no-repeat; }

  .theme-advanced-calc {
    &:hover {
      &::after {
        opacity: 1;
      }
    }

    &::before {
      background-image: linear-gradient(239.5deg, rgba(73, 234, 203, 0.45) 10.38%, rgba(13, 134, 202, 0.45) 68.34%);
    }
    &::after {
      background-image: linear-gradient(239.5deg, rgba(13, 134, 202, 0.45) 10.38%, rgba(73, 234, 203, 0.45) 68.34%);
      opacity: 0;
    }
  }

  .theme-stroke {
    @apply stroke-[#0D86CA];
  }

  .theme-ui-select .ui-select__control {
    &::before {
      background-image: linear-gradient(242.56deg, #49EACB 18.11%, #0D86CA 80.66%), linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04));
    }
    &::after {
      background-image: linear-gradient(242.56deg, #49EACB 18.11%, #0D86CA 80.66%), linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04));
    }
  }
}
