.theme-support {
  background: url('/assets/images/support.png') 50% 50% / contain no-repeat;
  width: 115px;
  height: 115px;

  @apply inline-block;
}

.theme-text-highlight {
  background: linear-gradient(91.14deg, #ff5400 -10.68%, #ecc203 91.68%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s linear;

  &:not(.shadow) { text-shadow: none; }
  &.shadow { text-shadow: 0 0 50px rgba(#ddb88f, 0.3); }

  &.hover-light {
    &:hover,
    &:focus {
      text-shadow: 0 0 50px rgba(#ddb88f, 0.9);
    }
  }
}

.theme-stroke {
  @apply stroke-[#FF9500];
}

.theme-color {
  @apply text-[#FF9500]
}


@import "btc-theme";
@import "etc-theme";
@import "rvn-theme";
@import "kas-theme";
@import "dnx-theme";
@import "gram-theme";