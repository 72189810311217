.theme-input-hovered {
  & > button[name="prefix"], & > button[name="suffix"] {
    &::before {
      opacity: 0;
    }

    &::after {
      opacity: 1;
    }
  }

  & > div {
    &::after {
      opacity: 1;
    }
  }
}

.theme-prefix-hovered {
  & > button[name="prefix"] {
    &::before {
      opacity: 0;
    }

    &::after {
      background-image: linear-gradient(221.19deg, rgba(125, 254, 161, 0.85) -43.08%, rgba(196, 196, 196, 0) 123.25%, #2F3D33 123.25%),
      linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04));
      opacity: 1;
    }
  }

  & > div, & > button[name="suffix"] {
    &::after {
      opacity: 1;
    }
  }
}

.theme-suffix-hovered {
  & > button[name="suffix"] {
    &::before {
      opacity: 0;
    }

    &::after {
      background-image: linear-gradient(221.19deg, rgba(125, 254, 161, 0.85) -43.08%, rgba(196, 196, 196, 0) 123.25%, #2F3D33 123.25%),
      linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04));
      opacity: 1;
    }
  }

  & > div, & > button[name="prefix"] {
    &::after {
      opacity: 1;
    }
  }
}

.theme-input-focused, .theme-prefix-focused, .theme-suffix-focused {
  & > button[name="prefix"], & > button[name="suffix"] {
    &::before {
      opacity: 0;
      background-image: linear-gradient(221.19deg, rgba(125, 254, 161, 0.85) -43.08%, rgba(196, 196, 196, 0) 123.25%, #2F3D33 123.25%),
      linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04));
    }

    &::after {
      opacity: 1;
      background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
    }

    &:hover {
      &::before {
        opacity: 1;

      }

      &::after {
        opacity: 0;
      }
    }
  }

  & > div {
    &::after {
      opacity: 1;
      background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
    }
  }
}

.theme-prefix-focused {
  & > button[name="prefix"] {
    &::before {
      opacity: 0;
    }

    &::after {
      background-image: linear-gradient(221.19deg, rgba(125, 254, 161, 0.85) -43.08%, rgba(196, 196, 196, 0) 123.25%, #2F3D33 123.25%),
      linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04));
      opacity: 1;
    }
  }

  & > div, & > button[name="suffix"] {
    &::before {
      opacity: 0;
    }

    &::after {
      background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
      opacity: 1;
    }
  }
}

.theme-suffix-focused {
  & > button[name="suffix"] {
    &::before {
      opacity: 0;
    }

    &::after {
      background-image: linear-gradient(221.19deg, rgba(125, 254, 161, 0.85) -43.08%, rgba(196, 196, 196, 0) 123.25%, #2F3D33 123.25%),
      linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04));
      opacity: 1;
    }
  }

  & > div, & > button[name="prefix"] {
    &::after {
      background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
      opacity: 1;
    }
  }
}
