.theme-GRAM {
  .theme-btn {
    &::before { background-image: linear-gradient(90deg, #4580E4 0%, #4CA1E0 35%, #5ECDDB 100%) !important; }
    &::after { background-image: linear-gradient(90deg, #5ECDDB 0%, #4CA1E0 35%, #4580E4 100%) !important; }
  }
  .theme-explore-btn {
    &::before { background-image: linear-gradient(90deg, #4580E4 0%, #4CA1E0 35%, #5ECDDB 100%) !important; }
    &::after { background-image: linear-gradient(90deg, #5ECDDB 0%, #4CA1E0 35%, #4580E4 100%) !important; }
  }


  .theme-text-highlight {
    background: linear-gradient(90deg, #4580E4 0%, #4CA1E0 35%, #5ECDDB 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .theme-color {
    @apply text-[#55B6DE];
  }

  .theme-border {
    @apply border-[#55B6DE];
  }

  .theme-gradient-color {
    background: linear-gradient(90deg, #4580E4 0.37%, #4CA1E0 26.87%, #5ECDDB 76.07%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .theme-support {
    background: url('/assets/images/support-gram.png') 50% 50% / contain no-repeat;
  }

  .theme-tutorial-btn.tutorial::after { background: url('/assets/images/tutorial-icon-gram-hover.svg') 50% 0 no-repeat; }

  .theme-advanced-calc {
    &:hover {
      &::after {
        opacity: 1;
      }
    }

    &::before {
      background-image: linear-gradient(90deg, #4580E4 0.37%, #4CA1E0 26.87%, #5ECDDB 76.07%);
    }
    &::after {
      background-image: linear-gradient(90deg, #5ECDDB 0.37%, #4CA1E0 26.87%, #4580E4 76.07%);
      opacity: 0;
    }
  }

  .theme-stroke {
    @apply stroke-[#55B6DE];
  }

  .theme-ui-select .ui-select__control {
    &::before {
      background-image: linear-gradient(90deg, #4580E4 0.37%, #4CA1E0 26.87%, #5ECDDB 76.07%);
    }
    &::after {
      background-image: linear-gradient(90deg, #5ECDDB 0.37%, #4CA1E0 26.87%, #4580E4 76.07%);

    }
  }
}
