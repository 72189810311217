.theme-DNX {
  @import "HardwareCounter/dnx-hardware-counter";

  .theme-btn {
    &::before { background-image: linear-gradient(180deg, #86FFFE 0%, #2AB9F9 100%) !important; }
    &::after { background-image: linear-gradient(180deg, #2AB9F9 0%, #86FFFE 100%) !important; }
  }
  .theme-explore-btn {
    &::before { background-image: linear-gradient(180deg, #86FFFE 0%, #2AB9F9 100%) !important; }
    &::after { background-image: linear-gradient(180deg, #2AB9F9 0%, #86FFFE 100%) !important; }
  }


  .theme-text-highlight {
    background: linear-gradient(180deg, #31E9E6 0%, #0873FB 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .theme-color {
    @apply text-[#45B1FF];
  }

  .theme-gradient-color {
    background-image: linear-gradient(180deg, #31E9E6 0%, #0873FB 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .theme-support {
    background: url('/assets/images/support-dnx.png') 50% 50% / contain no-repeat;
  }

  .theme-tutorial-btn.tutorial::after { background: url('/assets/images/tutorial-icon-dnx-hover.svg') 50% 0 no-repeat; }
  //.theme-tutorial-btn.video::after { background: url('/assets/images/tutorial-video-icon-kas-hover.svg') 50% 0 no-repeat; }

  .theme-advanced-calc {
    &:hover {
      &::after {
        opacity: 1;
      }
    }

    &::before {
      background-image: linear-gradient(180deg, #86FFFE 0%, #2AB9F9 100%);
    }
    &::after {
      background-image: linear-gradient(180deg, #2AB9F9 0%, #86FFFE 100%);
      opacity: 0;
    }
  }
  .theme-stroke {
    @apply stroke-[#0D86CA];
  }

  .theme-ui-select .ui-select__control {
    &::before {
      background-image: linear-gradient(180deg, #86FFFE 0%, #2AB9F9 100%);
    }
    &::after {
      background-image: linear-gradient(180deg, #2AB9F9 0%, #86FFFE 100%);
    }
  }
}
